'use client';
import React, { memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/navigation';

import SmartLink from '@/app/_components/SmartLink';
import {
	Avatar,
	AvatarFallback,
	AvatarImage,
} from '@/app/_components/ui/avatar';
import { Button } from '@/app/_components/ui/button';
import {
	DropdownMenu,
	DropdownMenuContent,
	DropdownMenuGroup,
	DropdownMenuItem,
	DropdownMenuLabel,
	DropdownMenuSeparator,
	DropdownMenuTrigger,
} from '@/app/_components/ui/dropdown-menu';
import { navigation, ROUTERS } from '@/constant';
import { useI18n } from '@/locales/client';
import {
	AudioActions,
	AudioType,
	GlobalDispatch,
	ThemeSelectors,
} from '@/store';
import { AuthActions, AuthSelector } from '@/store/Auth';
import { GeneralActions, GeneralSelectors, ModalType } from '@/store/General';
import { ProfileSelectors } from '@/store/Profile';
import { getFullName } from '@/utils';

const LDLogin = dynamic(() => import('src/app/_components/Modals/LoginModal'), {
	ssr: false,
});

const LDRegister = dynamic(
	() => import('src/app/_components/Modals/RegisterModal'),
	{
		ssr: false,
	}
);

const LDForgotPassword = dynamic(
	() => import('src/app/_components/Modals/ForgotPasswordModal'),
	{
		ssr: false,
	}
);

const AlertModal = dynamic(
	() => import('src/app/_components/Modals/AlertModal'),
	{
		ssr: false,
	}
);

const LDHeader = () => {
	const modalTypes = useSelector(GeneralSelectors.selectModalTypes);

	const logged = useSelector(AuthSelector.selectLogged);
	const profile: any = useSelector(ProfileSelectors.selectProfile);
	const theme = useSelector(ThemeSelectors.themeSelector);
	const router = useRouter();
	const { t }: any = useI18n();

	const isSignIn = modalTypes.includes(ModalType.SIGN_IN);
	const isSignUp = modalTypes.includes(ModalType.SIGN_UP);
	const isForgot = modalTypes.includes(ModalType.FORGOT);
	const isAlertLogout = modalTypes.includes(ModalType.ALERT);

	const onOpenSignUp = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.closeModalByType(ModalType.SIGN_IN));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.SIGN_UP));
	}, []);

	const onOpenSignIn = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.closeModalByType(ModalType.SIGN_UP));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.SIGN_IN));
	}, []);

	const onOpenForgot = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.FORGOT));
	}, []);

	const onAlertLogout = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(GeneralActions.openModalByType(ModalType.ALERT));
	}, []);

	const onLogout = useCallback(() => {
		GlobalDispatch(AudioActions.playAudioRequest(AudioType.CLICK));
		GlobalDispatch(AuthActions.logoutRequest({ router }));
		GlobalDispatch(GeneralActions.resetModalTypes());
	}, [router]);

	return (
		<div className="row gap-3">
			<SmartLink
				href={ROUTERS.NEWS}
				onClick={() =>
					GlobalDispatch(
						AudioActions.playAudioRequest(AudioType.CLICK)
					)
				}
			>
				<button
					title={t('footer.news') as string}
					className="md:text-14 relative z-10 hidden max-w-max rounded-md bg-transparent px-1 py-2.5 text-xs font-semibold text-white ring-1 ring-transparent duration-300 hover:shadow-md hover:ring-white/10 active:translate-y-[1px] md:block md:px-4"
				>
					{t('footer.news')}
				</button>
			</SmartLink>

			{logged ? (
				<div className="row gap-3">
					<div className="text-sm font-medium text-white">
						{profile?.fullName || profile?.email}
					</div>
					<DropdownMenu>
						<DropdownMenuTrigger asChild>
							<Button
								variant="ghost"
								onClick={() =>
									GlobalDispatch(
										AudioActions.playAudioRequest(
											AudioType.CLICK
										)
									)
								}
								className="relative h-8 w-8 rounded-full"
							>
								<Avatar className="h-8 w-8">
									<AvatarImage
										src="/avatars/01.png"
										alt="@shadcn"
									/>
									<AvatarFallback>
										{getFullName(
											profile?.fullName || profile?.email
										)}
									</AvatarFallback>
								</Avatar>
							</Button>
						</DropdownMenuTrigger>
						<DropdownMenuContent
							className="w-56"
							align="end"
							forceMount
						>
							<DropdownMenuLabel className="font-normal">
								<div className="flex flex-col space-y-1">
									<p className="text-sm font-medium leading-none">
										{profile?.fullName}
									</p>
									<p className="text-muted-foreground text-xs leading-none">
										{profile?.email}
									</p>
								</div>
							</DropdownMenuLabel>
							<DropdownMenuSeparator />
							<DropdownMenuGroup>
								{navigation.map((item: any, idx: number) => (
									<DropdownMenuItem key={idx}>
										<SmartLink
											onClick={() =>
												GlobalDispatch(
													AudioActions.playAudioRequest(
														AudioType.CLICK
													)
												)
											}
											href={item.href}
										>
											{t(item.label)}
										</SmartLink>
									</DropdownMenuItem>
								))}
							</DropdownMenuGroup>
							<DropdownMenuSeparator />
							<DropdownMenuItem
								className="cursor-pointer"
								onClick={onAlertLogout}
							>
								{t('menu.logout')}
							</DropdownMenuItem>
						</DropdownMenuContent>
					</DropdownMenu>
				</div>
			) : (
				<>
					<button
						onClick={onOpenSignUp}
						title={t('register.title') as string}
						className="md:text-14 relative z-10 max-w-max rounded-md bg-transparent px-2 py-2.5 text-xs font-semibold text-white ring-1 ring-transparent duration-300 hover:shadow-md hover:ring-white/10 active:translate-y-[1px] md:px-4"
					>
						{t('register.label')}
					</button>
					<button
						style={{ background: theme.bg_modal_btn }}
						onClick={onOpenSignIn}
						title={t('login.title') as string}
						className="md:text-14 relative z-10 max-w-max rounded-md bg-transparent px-2 py-2.5 text-xs font-semibold text-white ring-1 ring-transparent duration-300 hover:shadow-md hover:ring-white/10 active:translate-y-[1px] md:px-4"
					>
						{t('login.title')}
					</button>
				</>
			)}
			{isSignIn && (
				<LDLogin
					onOpenSignUp={onOpenSignUp}
					onForgotPassword={onOpenForgot}
				/>
			)}
			{isSignUp && (
				<LDRegister
					onOpenSignIn={onOpenSignIn}
					onForgotPassword={onOpenForgot}
				/>
			)}
			{isForgot && (
				<LDForgotPassword onResetPasswordSuccess={onOpenSignIn} />
			)}
			{isAlertLogout && (
				<AlertModal title={'alert.title'} submit={onLogout} />
			)}
		</div>
	);
};

export default memo(LDHeader);
