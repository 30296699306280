'use client';
import React, { PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import AudioPlayer from '@/app/_components/AudioPlayer';
import Analytic from '@/app/_components/Common/Analytic';
import WrapperContainer from '@/app/_components/WapperContainer';
import { persistor, store } from '@/store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PersistGate } from 'redux-persist/integration/react';

const queryClient = new QueryClient();

function GlobalLayout({ children }: PropsWithChildren) {
	return (
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<WrapperContainer>{children}</WrapperContainer>
					<AudioPlayer />
					<Analytic id={'G-YJWDXM3T57'} />
				</PersistGate>
			</Provider>
		</QueryClientProvider>
	);
}

export default GlobalLayout;
