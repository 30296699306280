import React, { memo } from 'react';
import Image, { ImageProps } from 'next/image';

import { cn } from '@/utils';

interface INextImage extends Omit<ImageProps, 'alt' | 'src'> {
	alt?: string;
	src: string | undefined | any;
}
const NextImage: React.FC<INextImage> = ({
	src,
	alt = '',
	className,
	...props
}) => {
	if (!src) {
		return (
			<div
				className={cn(
					'bg-gray-100',
					!props?.width && 'h-auto w-full',
					className
				)}
				style={{
					aspectRatio:
						props.width && props.height
							? `${props.width}/${props.height}`
							: undefined,
				}}
			/>
		);
	}

	return (
		<Image
			src={src}
			alt={
				alt
					? alt + ' - Luck4You - quaysotrungthuong.vn'
					: 'Luck4You - quaysotrungthuong.vn - Giải pháp quay số trúng thưởng toàn diện cho cá nhân và doanh nghiệp'
			}
			className={cn(!props?.width && 'h-auto w-full', className)}
			quality={100}
			{...(props as any)}
			sizes="(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw"
		/>
	);
};

export default memo(NextImage);
