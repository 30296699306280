'use client';
import React, { ReactNode } from 'react';
import dynamic from 'next/dynamic';

import BackgroundImageLayout from '@/app/_components/BackgroundImageLayout';
import LDHeader from '@/app/_components/Header';
import SmartLink from '@/app/_components/SmartLink';
import { MODE, ROUTERS } from '@/constant';
import { useAppSelector } from '@/store';
import { GeneralSelectors } from '@/store/General';

import clsx from 'clsx';

const Messenger = dynamic(() => import('./Common/Messenger'), {
	ssr: false,
});

function MainLayout({ children }: { children: ReactNode }) {
	const mode = useAppSelector(GeneralSelectors.selectMode);
	return (
		<>
			<div
				className={clsx(
					'row container relative z-50 h-[86px] px-6',
					mode === MODE.Dial ? 'justify-center' : 'justify-between'
				)}
			>
				<SmartLink href={ROUTERS.HOME} className="flex">
					<img
						src={'/image/logo.png'}
						alt={'logo'}
						height={50}
						className="h-[50px]"
					/>
				</SmartLink>
				{mode !== MODE.Dial && <LDHeader />}
			</div>
			{children}
			<BackgroundImageLayout />
			<Messenger pageId="425714063965997" />
		</>
	);
}

export default MainLayout;
