'use client';
import { PropsWithChildren, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/navigation';

import { ServiceApi } from '@/services';
import {
	AuthActions,
	AuthSelector,
	GlobalDispatch,
	StartupActions,
} from '@/store';
import { LuckyDrawActions, LuckyDrawSelectors } from '@/store/LuckyDraw';

import MainLayout from './MainLayout';

interface WrapperContainerProps extends PropsWithChildren {}

const WrapperContainer: React.FC<WrapperContainerProps> = ({ children }) => {
	const logged = useSelector(AuthSelector.selectLogged);
	const firstEvent = useSelector(LuckyDrawSelectors.selectFirstEvent);
	const router = useRouter();

	// Handle startup and authentication monitoring
	useEffect(() => {
		const initializeApp = () => {
			GlobalDispatch(StartupActions.startup());
		};

		const setupAuthMonitor = () => {
			const handleAuthError = (response: any) => {
				if (response?.data?.error?.statusCode === 401) {
					GlobalDispatch(AuthActions.logoutRequest({ router }));
				}
			};

			ServiceApi.api.addMonitor(handleAuthError);
		};

		initializeApp();
		setupAuthMonitor();
	}, [router]);

	// Handle event management for logged in users
	useEffect(() => {
		const handleEvents = () => {
			if (!logged) return;

			if (firstEvent?.name) {
				GlobalDispatch(
					LuckyDrawActions.createEventRequest({
						data: firstEvent,
					})
				);
			}
			GlobalDispatch(LuckyDrawActions.getEventListRequest());
		};

		handleEvents();
	}, [logged, firstEvent]);

	return <MainLayout>{children}</MainLayout>;
};

export default WrapperContainer;
