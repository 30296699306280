'use client';
import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import NextImage from '@/app/_components/Common/NextImage';
import { ThemeSelectors } from '@/store';

const BackgroundImageLayout = () => {
	const theme = useSelector(ThemeSelectors.themeSelector);
	return (
		<div className="fixed inset-0 z-[-1]">
			<NextImage
				src={theme?.bg_image?.url || ''}
				fill
				alt="Lucky Draw"
				className="bg-fixed object-cover object-bottom"
				placeholder="blur"
				blurDataURL={theme?.bg_image?.placeholder}
			/>
		</div>
	);
};

export default memo(BackgroundImageLayout);
